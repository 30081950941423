// Highlights.js
import React from 'react';
import './Highlights.css';
import qualityImage from './Media/quality.jpg';
import labImage from './Media/lab.jpg';
import documentationImage from './Media/documentation.jpg';
import trustImage from './Media/trust.jpeg';

const Highlights = () => {
    return (
      <div className="Highlights-section">
        <div className="highlight">
          <img src={qualityImage} alt="Top Quality Product" />
          <h3>Top Quality Product</h3>
          <p>We provide top quality magnesium turnings that meet the highest standards for purity and performance in various industrial applications.</p>
        </div>
        <div className="highlight">
          <img src={labImage} alt="Laboratory Analysis" />
          <h3>Laboratory Analysis</h3>
          <p>Our products undergo rigorous laboratory analysis to ensure they meet the precise requirements of your chemical processes.</p>
        </div>
        <div className="highlight">
          <img src={documentationImage} alt="Prompt Documentation" />
          <h3>Documentary Compliance</h3>
          <p>We offer prompt and accurate documentation for all our products, ensuring you have the information you need when you need it.</p>
        </div>
        <div className="highlight">
          <img src={trustImage} alt="Building Trust" />
          <h3>Building Trust</h3>
          <p>Our commitment to quality and customer satisfaction helps us build trust with our clients and partners in the industry.</p>
        </div>
      </div>
    );
  };
  
  export default Highlights;
