import './App.css';
import logo from './Media/logo.png';
import emailjs from 'emailjs-com';
import { useState, useRef, useEffect } from 'react';
import isoCert1 from './Media/9001.jpg';
import isoCert2 from './Media/14001.jpg';
import isoCert3 from './Media/41894.jpg';
import isoCert4 from './Media/45001.jpg';
import mt01 from './Media/SMC-MT-01.jpg';
import mt02 from './Media/SMC-MT-02.jpg';
import mt03 from './Media/SMC-MT-03.jpg';
import zed from './Media/Zed.jpg';
import headerbackground from './Media/headerbackgroundimage.jpg';
import specificationsbackground from './Media/specificationsbackground.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { Carousel } from 'react-responsive-carousel';
import { FaWhatsapp, FaLinkedin, FaFacebook, FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import Highlights from './Highlights';


function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const carouselRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (carouselRef.current) { 
        carouselRef.current.next();
      }
    }, 5000); // Adjust the interval as needed (milliseconds)

    return () => clearInterval(interval);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_i637zhp', 'template_ri6lzof', e.target, 'W5UEG4P-mT9VCGFFo')
      .then((result) => {
          console.log(result.text);
          setFormSubmitted(true);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="App">
      <nav className="navbar">
        <div className="navbar-logo">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className={`side-panel ${menuOpen ? 'open' : ''}`}>
          <ul className="navbar-links">
            <li><a href="#home" onClick={toggleMenu}>Home</a></li>
            <li><a href="#specifications" onClick={toggleMenu}>Specifications</a></li>
            <li><a href="#certificates" onClick={toggleMenu}>About</a></li>
            <li><a href="#contact" onClick={toggleMenu}>Contact</a></li>
          </ul>
        </div>
      </nav>
      <div>
        <ul className="company-name">
        <li><a href="#home" onClick={toggleMenu}>Samir MetaChem - ISO & GMP Certified Company</a></li>
        </ul>
        </div>
      <header id="home" className="App-header"
      style={{
        backgroundImage: `url(${headerbackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      >
      
        <div className="header-content">
          <h1>Powering Progress with Precision Magnesium Turning Across India</h1>
          <p>
          Samir MetaChem, a leading manufacturer in the magnesium turning industry. Based in Ankleshwar, Gujarat, India, we specialize in providing high-quality magnesium turnings for a wide range of applications in pharmaceuticals, agro- chemical and various industries dealing in organic and inorganic chemistry.<br></br>
          <br></br>With our state-of-the-art manufacturing facility and a team of skilled professionals, we consistently deliver products that meet the highest standards  and customer specific requirements for purity and to achieve process performance.<br></br>
          <br></br>At Samir MetaChem, we understand the critical application which magnesium turnings play in various types of industrial chemical reactions, from pharmaceuticals to agrochem and fine chem industries.<br></br>
          <br></br>That's why we prioritize precision, quality and reliability in our production processes, ensuring that our customers receive consistent and top-notch products throughout customers' end use product lifecycle.<br></br>
          <br></br>Whether you need magnesium turnings for research and development or industrial applications, we are committed to providing you with exceptional service and tailored solutions to match your requirements.<br></br>
          <br></br>Trust Samir MetaChem for all your magnesium turning requirements and experience the difference in quality and customer satisfaction.
          </p>
        </div>
      </header>
      <div id="specifications" className="Sub-header"
      style={{
        backgroundImage: `url(${specificationsbackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <h2 className="Sub-header-content">High Purity Reagent Grade Magnesium Turnings for Laboratory and product development purposes</h2>
         <p>Use | Superior 98 to 99.5% Purity | Lab-Grade Turnings
          Introducing our Reagent Grade Magnesium Turnings, a superior laboratory-grade product with a tremendous purity level of 98 to 99.5%. Expertly designed and devised to give an exceptional right start to the chemical reactions, these Magnesium Turnings come with an admirable promise of reliable performance and broad applications.<br></br>
          <br></br>Defined by a Chemical Formula of Mg and a CAS Number 7439-95-4, the Magnesium Turnings are a quintessential addition to your laboratory and chemical reaction process as a catalyst resource. From serving as a robust reducing agent to its far-reaching applications in organic synthesis and Grignard reactions, the possibilities with these Magnesium Turnings are wide and varied.
         </p>
      </div>
      <div id="Products" className="Certificates-section">
        <h2>Top Quality Products</h2>
        <Carousel
          showThumbs={false} 
          autoPlay 
          infiniteLoop 
          showStatus={false}
          interval={2000}
          centerMode
          centerSlidePercentage={50}
        >
          <div>
            <img src={mt01} alt="MT01" />
          </div>
          <div>
            <img src={mt02} alt="MT02" />
          </div>
          <div>
            <img src={mt03} alt="MT03"/>
          </div>
        </Carousel>
        <h2>Explore our Product Catalogue and Quality Policy -
          <button
            onClick={() => window.open(`${process.env.PUBLIC_URL}/ProductCatalog.pdf`, '_blank')}
            className="catalog-button"
          > Products
          </button>
          <button
            onClick={() => window.open(`${process.env.PUBLIC_URL}/QualityPolicy.pdf`, '_blank')}
            className="catalog-button"
          > Quality Policy
          </button>
          </h2>
          <h2>MSDS (Material Safety Data Sheet) For Magnesium Turning -
              <button
            onClick={() => window.open(`${process.env.PUBLIC_URL}/MaterialSafety.pdf`, '_blank')}
            className="catalog-button"
          > MSDS
          </button>
            </h2>
      </div>
      <Highlights />
      <div id="certificates" className="Certificates-section">
        <h2>ISO and Qualification Certificates</h2>
        <Carousel
          showThumbs={false} 
          autoPlay 
          infiniteLoop 
          showStatus={false}
          interval={2000}
          centerMode
          centerSlidePercentage={50}
        >
          <div>
            <img src={isoCert1} alt="ISO Certificate 1" />
          </div>
          <div>
            <img src={isoCert2} alt="ISO Certificate 2" />
          </div>
          <div>
            <img src={isoCert3} alt="ISO Certificate 3"/>
          </div>
          <div>
            <img src={isoCert4} alt="ISO Certificate 4" />
          </div>
          <div>
            <img src={zed} alt="ISO Certificate 5" />
          </div>
        </Carousel>
      </div>
      <div id="contact" className="Contact-section">
      <h2 className="Whatsapp-section">Let's talk
      <a href="https://wa.me/+919409266145?text=Hello,+I+am+reaching+out+for+an+enquiry" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp size={40} color="#25D366" style={{ marginLeft: '10px' }} />
          </a>
          <a href="tel:+919409266145" target="_blank" rel="noopener noreferrer">
            <FaPhone size={40} color="#3498db" style={{ marginLeft: '10px' }} />
          </a>    
      </h2>
      <h2 className="Whatsapp-section">Write to us
      <a href="mailto:samir.metachem@gmail.com" target="_blank" rel="noopener noreferrer">
            <FaEnvelope size={40} color="#25D366" style={{ marginLeft: '10px' }} />
          </a>    
      </h2>
      <h2 className="social-text">Find us on Social Media 
          <a href="https://www.linkedin.com/company/samir-metachem/about/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={40} color="#0077B5" style={{ marginLeft: '10px' }} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61556130780540" target="_blank" rel="noopener noreferrer">
            <FaFacebook size={40} color="#4267B2" style={{ marginLeft: '10px' }} />
          </a>
        </h2>
        <h2>Contact Us</h2>
        <p>Fill out the form below to get in touch with us. We are here to answer any questions you may have and provide you with the best magnesium turning for your needs.</p>
        <form className="contact-form" onSubmit={sendEmail}>
          <div className="input-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="input-group">
            <label htmlFor="mobile">Contact Number:</label>
            <input type="phonenumber" id="mobile" name="mobile" required />
          </div>
          <div className="input-group">
            <label htmlFor="inquiry">Inquiry:</label>
            <textarea id="inquiry" name="inquiry" rows="4" required></textarea>
          </div>
          <button type="submit">Submit</button>
          {formSubmitted && <p>Thank you for your inquiry. We will get back to you soon.</p>}
        </form>
      </div>
      <div id="map" className="Map-section">
        <h2 className="map-text">
          Find us on Google Maps 
            <a href="https://www.google.com/maps/place/Samir+Metachem/@21.6102255,73.0338778,17z/data=!3m1!4b1!4m6!3m5!1s0x3be02397b7b32d7f:0xf3effef362f6a46e!8m2!3d21.6102205!4d73.0364527!16s%2Fg%2F11h3c2_8kp?entry=ttu" target="_blank" rel="noopener noreferrer">
            <FaMapMarkerAlt size={40} color="#EA4335" style={{ marginLeft: '10px' }} />
        </a>
  </h2>
  <h2>Address</h2>
  <p>Samir MetaChem - ISO & GMP Certified Company<br></br>
    Plot No.5143/A, Near Prime Chowkdi, GIDC, Ankleshwar, Gujarat <br /> Pincode - 393002 <br/>
  Call us on : 
    <a href="tel:+919409266145"> +919409266145</a> / 
    <a href="tel:+918160322790"> +918160322790</a></p>
</div>
    </div>
  );
}

export default App;
